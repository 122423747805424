/* autoprefixer grid: autoplace */
html, body {
	background: #FFFFFF;
	color: #000;
	--fontFamilyBase: "MGP Text", Arial, sans-serif;
	--fontFamilySecond: "MGP Display", Arial, sans-serif;
	--fontFamilyThird: "Heebo", sans-serif;
	font-family: var(--fontFamilyBase);
}

#root {
	/*height: 100%;*/
	/*min-height: 100%;*/
	font-family: var(--fontFamilyBase);
	/*display: flex;*/
	/*flex-flow: column;*/
}

* {
	-webkit-tap-highlight-color: transparent;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body.is-menu-open {
	overflow: hidden;
	position: fixed;
}

select::-ms-expand {
    display: none;
}

select {
	-ms-user-select: none;
	font-family: 'MotoGPText';
}

textarea{
	-ms-overflow-style: none
}

input::-ms-clear {
    display: none;
}

.reactour__helper [data-tour-elem="controls"] {
	position: absolute;
	bottom: 75px;
	left: 50%;
	transform: translateX(-50%);
}

.reactour__helper [data-tour-elem="controls"] .reactour__dot {
	background: #FFE9E9;
	border-color: #FFE9E9;
	width: 10px;
	height: 10px;
	margin-left: 15px;
	margin-right: 0;
}

.reactour__helper [data-tour-elem="controls"] .reactour__dot.reactour__dot--is-active {
	transform: scale(1);
	background: #CF003E;
}